<template>
  <fieldset :class="{ box: !isViewModeCreate }">
    <page-title
      :title="$t('page.validateInfo.title')"
      @edit="$emit('edit')"
    >
      <p class="has-text-justified">
        {{ $t('page.validateInfo.description') }}
      </p>
    </page-title>
    <div class="box">
      <h3 class="is-size-6">{{ $t('page.validateInfo.neq') }}</h3>
      <p>{{ this.storedModel.neq.neq }}</p>
      <h3 class="is-size-6 mt-4">{{ $t('page.validateInfo.name') }}</h3>
      <p>{{ this.storedModel.neq.nomEntreprise }}</p>
      <h3 class="is-size-6 mt-4">{{ $t('page.validateInfo.address') }}</h3>
      <p class="is-pre-wrap">
        {{ this.storedModel.neq.adresse ? this.storedModel.neq.adresse.trim() : 'N/A' }}
      </p>
      <h3 class="is-size-6 mt-4">{{ $t('page.validateInfo.regime') }}</h3>
      <p>
        {{ this.storedModel.neq.regimeCourant || 'N/A' }}
      </p>
      <h3 class="is-size-6 mt-4">{{ $t('page.validateInfo.employeeCount') }}</h3>
      <p>
        {{ this.storedModel.neq.nbSalarie || 'N/A' }}
      </p>
      <h3 class="is-size-6 mt-4">{{ $t('page.validateInfo.firstSector') }}</h3>
      <div v-if="this.storedModel.neq.secteurActivite.premier">
        <p>
          {{ $t('page.validateInfo.code') + ' : ' + (this.storedModel.neq.secteurActivite.premier.code || 'N/A') }}
        </p>
        <p>
          {{ $t('page.validateInfo.activity') + ' : ' + (this.storedModel.neq.secteurActivite.premier.activite || 'N/A') }}
        </p>
      </div>
      <p v-else>N/A</p>
      <h3 class="is-size-6 mt-4">{{ $t('page.validateInfo.secondSector') }}</h3>
      <div v-if="this.storedModel.neq.secteurActivite.deuxieme">
        <p>
          {{ $t('page.validateInfo.code') + ' : ' + (this.storedModel.neq.secteurActivite.deuxieme.code || 'N/A') }}
        </p>
        <p>
          {{ $t('page.validateInfo.activity') + ' : ' + (this.storedModel.neq.secteurActivite.deuxieme.activite || 'N/A') }}
        </p>
      </div>
      <p v-else>N/A</p>
    </div>
    <b-field :label="$t('form.field.validateinfochoice.label')">
      <div v-if="isViewModeCreate">
        <b-radio
          v-model="model.isValid"
          :native-value="false"
          name="infovalid"
        >
          {{ $t('form.field.validateinfochoice.no') }}
        </b-radio>
        <b-radio
          v-model="model.isValid"
          :native-value="true"
          name="infovalid"
        >
          {{ $t('form.field.validateinfochoice.yes') }}
        </b-radio>
      </div>
      <span v-else>{{ $t('form.field.validateinfochoice.' + (model.isValid ? 'yes' : 'no')) }}</span>
    </b-field>
    <b-field
      v-if="model.isValid === false"
      :label="getFieldLabel('changes')"
      :type="getFieldType('changes')"
      :message="getFieldMessage('changes')"
    >
      <b-input
        v-if="isFieldEditable('$.validateInfo.changes')"
        v-model="model.changes"
        maxlength="2000"
        type="textarea"
        @blur="validateChanges"
        autocomplete="off"
      >
      </b-input>
      <span v-else>{{ model.changes }}</span>
    </b-field>
  </fieldset>
</template>

<script>
import page from '@/mixins/page';

export default {
  name: 'PageValidateInfo',
  mixins: [
    page('validateInfo')
  ],
  methods: {
    validateChanges () {
      this.validateNotEmpty('changes', this.model.changes);
    },
    validate () {
      if (!this.model.isValid)
        this.validateChanges();
    }
  }
};
</script>
